//
// card.scss
//

.card {
  box-shadow: $card-box-shadow;
  margin-bottom: $grid-gutter-width;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.card-drop {
  font-size: 20px;
  line-height: 0;
  color: inherit;
}

.card-widgets {
  float: right;
  height: 16px;
  > a {
    color: inherit;
    font-size: 18px;
    display: inline-block;
    line-height: 1;

    &.collapsed {
      i {
        &:before {
          content: "\F0415";
        }
      }
    }
  }
}

.img-product {
  width: 70px;
  height: 70px;
}

.btn-login {
  width: 100%;
  background-color: #bf0d3e;
}

.none{
  display: none;
}

.line-border-rodape{
    padding: 10px 10px !important;
}

.content-rodape{
    margin: 0px !important;
}

.mr-10 {
  margin-right: 10px;
}

.container-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container-unlink {
  display: flex;
}

#unlink_user {
  padding-left: 15px;
}

.is-invalid-product {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #f1556c;
}

.content-table {
  background-color: #e7e7e8 !important;
  color: #6e6e70;
  border: 3px solid #8f9092;
  border-radius: 10px;
  max-width: 8.08%;
}

.body-table {
  border-radius: 3px solid red;
}

.modal-60 {
  width: 60vh;
}

#table-calendar {
  overflow-y: scroll;
  margin-top: 20px !important;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ee591d;
  height: 120px;
  padding: 0px 20px;
}

.label-farm {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
}

.tr-table {
  padding: 10px 0px;
  background-color: red !important;
}

.container-months {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 10px 10px;
}

.months {
  padding: 13px 0px 12px 0px;
  text-align: center;
  border: 1px solid #8f9092;
  border-radius: 10px;
  font-size: 1em;
  color: #6e6e70;
  display: block;
  overflow: hidden;
  font-weight: 700;
  background-color: #e7e7e8 !important;
}

.container-body-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.center-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content-body {
  border-radius: 10px;
  color: black;
  font-weight: 700;
  background-color: #b2b1b1 !important;
  height: 130px;
  min-height: 150px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 10px 1px !important;
}

.content-body-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.content-body-management {
  color: black;
  font-weight: 700;
  height: 18px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 1px !important;
}

.mt-25 {
  margin-top: 25px;
}

.content-tabless {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
}

@media (max-width: 550px) {
  .container-buttons {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .button-report {
    width: 100%;
    margin: 10px 0px;
  }
}

.report {
  margin: 0px 10px;
}

.content-mobile {
  overflow-x: scroll;
}

.container-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.content-animal {
  text-align: center;
  margin-top: 15px;
  font-weight: 500;
  font-size: 14px;
  color: black;
  width: 300px;
  border: 1px solid black;
}
.content-manejo {
  width: 5% !important;
  min-height: 100px;
  background-color: #f75732 !important;
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 700;
}

.manejo-name {
  text-align: center;
  font-size: 10px;
  color: white;
  background-color: #f75732;
  max-width: 10%;
  width: 10%;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.manejo-name-1 {
  text-align: center;
  font-size: 10px;
  color: white;
  max-width: 10%;
  width: 10%;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.animal-name {
  text-align: center;
  font-size: 14px;
  color: white;
  background-color: #f98a28;
  max-width: 10%;
  width: 10%;
  height: 130px;
  min-height: 150px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-center {
  margin: 0px 5px;
}

.line-border {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #8f9092;
  text-align: center;
  padding: 10px 10px;
}

// Card title / Card Header
.card-title,
.card-header {
  margin-top: 0;
}

//Card disable loading (Custom Cards)
.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: $card-border-radius;
  background: $card-overlay-bg;
  cursor: progress;

  .card-portlets-loader {
    background-color: $card-overlay-color;
    animation: rotatebox 1.2s infinite ease-in-out;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 3px;
    margin-left: -($grid-gutter-width * 0.5);
    margin-top: -($grid-gutter-width * 0.5);
  }
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.sub-header {
  font-size: 0.875rem;
  margin-bottom: $grid-gutter-width;
  color: $text-muted;
}
